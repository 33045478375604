<template>
  <div style="background-color: rgba(240, 237, 237,0.3);height: 100%;position: absolute;top: 0;">
    <h3 align="center">襄阳燃气更新了</h3>
  <van-circle
  v-model:current-rate="currentRate"
  :rate="100"
  :color="gradientColor"
  text="更新提示"
  style="margin-top: 3vh;"
/>
<van-collapse v-model="activeName" accordion style="margin-top: 2vh;">
  <van-collapse-item title="网络差的更新" name="1">
    当您所处的环境网络很差的时候系统会提醒您<span style="color:red">【网络差,请更换网络再下单】</span>，建议更换WIFI或到网络好的地方下单。
  </van-collapse-item>
  <van-collapse-item title="最新的订单状态" name="2">
    首页会滚动提醒您最后一笔订单的状态，例如：已送达、退款处理、待送达、待指派送气员等。
  </van-collapse-item>
  <van-collapse-item title="陶瓷码的更新" name="3">
    方便钢瓶的统一管理，系统将使用最新的陶瓷码替换之前的粘胶条形码。
  </van-collapse-item>
  <van-collapse-item title="重要提醒" name="4">
    如果进入系统后出现空白或无法订气请点击<span style="color:red">右上方三个点</span>刷新当前页。
  </van-collapse-item>
</van-collapse>
<br><br>
<div style="width:90vw;margin: 0 auto;" v-if="isshow.read">
  <van-button type="danger" block @click="buy">前往订气</van-button>
</div>
<div style="width:90vw;margin: 0 auto;" v-else>
  <van-button type="primary" block >正在前往订气页面</van-button>
</div>
</div>
</template>

<script setup>
import { reactive } from 'vue';
import { onMounted } from 'vue';
import { ref } from 'vue';
const isshow = reactive({
  read : true
})
const currentRate = ref(0);
    const gradientColor = {
      '0%': '#3fecff',
      '100%': '#6149f6',
    };
    const activeName = ref('1');
    const buy = ()=>{
      window.location.href='http://weixin.xyyxrq.com/api/store/getlogin'
    }
    const set = ()=>{
      if(window.localStorage.getItem("readnum")){
        let n = Number(window.localStorage.getItem("readnum"))
        if(n>2){
          isshow.read = false
          setTimeout(() => {
            buy()
          }, 1000);
        }else{
          window.localStorage.setItem("readnum",n+1)
        }
      }else{
        window.localStorage.setItem("readnum",1)
      }
    }
    onMounted(()=>{
      set()
    })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
